import React, { createRef } from 'react';
import { Redirect } from 'react-router';
import Device from '../Misc/Device';
import { formatTime } from '../Misc/Helpers';
import LCSS from '../Views/LCSS';
import WSController, { ClientWSCommands } from './WSController';
import ReactTooltip from 'react-tooltip';
import { DeviceType } from "../Misc/Device";
import DescriptionIcon from '../assets/description.png';
import { Fragment } from 'react/cjs/react.production.min';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';

//#region Header definitions
/**
 * @type {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}[]}
 */
const dataHeadersAdmin = [
    { vName: "Addr", dName: "Address", tooltip: "", decimals: 0, unit: "" },
    // { vName: "Error", dName: "Error", tooltip: "", decimals: 0, unit: "" },
    { vName: "T1", dName: "Temp LC", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "T2", dName: "Temp Cabin", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "RPM", dName: "Fan RPM", tooltip: "", decimals: 0, unit: "" },
    { vName: "U1", dName: "U1", tooltip: "", decimals: 1, unit: "V" },
    { vName: "U2", dName: "U2", tooltip: "", decimals: 1, unit: "V" },
    { vName: "U3", dName: "U3", tooltip: "", decimals: 1, unit: "V" },
    { vName: "U4", dName: "U4", tooltip: "", decimals: 1, unit: "V" },
    { vName: "SETI1", dName: "Set I1", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "I1", dName: "I1", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "SETI2", dName: "Set I2", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "I2", dName: "I2", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "SETI3", dName: "Set I3", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "I3", dName: "I3", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "SETI4", dName: "Set I4", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "I4", dName: "I4", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "chCutoff", dName: "Cutoff", tooltip: "", decimals: 0, unit: "" },
    { vName: "bars", dName: "UVC Bars", tooltip: "", decimals: 0, unit: "" },
]

const dataHeadersAdminMobile = [
    { vName: "Addr", dName: "Address", tooltip: "", decimals: 0, unit: "" },
    // { vName: "Error", dName: "Error", tooltip: "", decimals: 0, unit: "" },
    { vName: "T1", dName: "Temp LC", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "T2", dName: "Temp Cabin", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "RPM", dName: "Fan RPM", tooltip: "", decimals: 0, unit: "" },
    { vName: "U1", dName: "U1", tooltip: "", decimals: 1, unit: "V" },
    { vName: "U2", dName: "U2", tooltip: "", decimals: 1, unit: "V" },
    { vName: "U3", dName: "U3", tooltip: "", decimals: 1, unit: "V" },
    { vName: "U4", dName: "U4", tooltip: "", decimals: 1, unit: "V" },
    { vName: "SETI1", dName: "Set I1", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "I1", dName: "I1", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "SETI2", dName: "Set I2", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "I2", dName: "I2", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "SETI3", dName: "Set I3", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "I3", dName: "I3", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "SETI4", dName: "Set I4", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "I4", dName: "I4", tooltip: "", decimals: 0, unit: "mA" },
]


const mobilePortrait = window.innerWidth < 768


/**
 * @type {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}[]}
 */
const masterDataHeadersAdmin = [
    { vName: "Addr", dName: "Address", tooltip: "", decimals: 0, unit: "" },
    // { vName: "Error", dName: "Error", tooltip: "", decimals: 0, unit: "" },
    { vName: "Uptime", dName: "Uptime", tooltip: "Uptime", decimals: 0, unit: "" },
    { vName: "UVCTime", dName: "UVC Time", tooltip: "UVC time", decimals: 0, unit: "" },
    { vName: "Boxcount", dName: "Total Trays", tooltip: "", decimals: 0, unit: "" },
    { vName: "ExtVolt", dName: "Ext Voltage", tooltip: "", decimals: 1, unit: "V" },
    { vName: "T1", dName: "Temp LCDIO", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "T2", dName: "Temp Cabin", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "RPM", dName: "Fan RPM", tooltip: "", decimals: 0, unit: "" },
    { vName: "chCutoff", dName: "Cutoff", tooltip: "", decimals: 0, unit: "" },
    { vName: "wifirssi", dName: "WiFi RSSI", tooltip: "", decimals: 0, unit: "" },
    { vName: "wifiretries", dName: "WiFi Retries", tooltip: "", decimals: 0, unit: "" },
    // { vName: "WiFi RSSI", dName: "WiFi RSSI", tooltip: "", decimals: 0, unit: "" },
]
/**
 * @type {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}[]}
 */
/*  const dataHeadersAdminMobile = [
    { vName: "Addr", dName: "Addr", tooltip: "", decimals: 0, unit: "" },
    // { vName: "Error", dName: "Err", tooltip: "", decimals: 0, unit: "" },
    { vName: "T1", dName: "Temp LC", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "T2", dName: "Temp Cabin", tooltip: "", decimals: 0, unit: "°C" },
] */
/**
 * @type {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}[]}
 */
const masterDataHeadersAdminMobile = [
    { vName: "Addr", dName: "Addr", tooltip: "", decimals: 0, unit: "" },
    // { vName: "Error", dName: "Err", tooltip: "", decimals: 0, unit: "" },
    { vName: "Uptime", dName: "Uptime", tooltip: "Uptime", decimals: 0, unit: "" },
    { vName: "UVCTime", dName: "UVC time", tooltip: "UVC time", decimals: 0, unit: "" },
    { vName: "Boxcount", dName: "Box count", tooltip: "", decimals: 0, unit: "" },
    { vName: "T1", dName: "Temp LCDIO", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "T2", dName: "Temp Cabin", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "chCutoff", dName: "Cutoff", tooltip: "", decimals: 0, unit: "" }
]


function formatISOToReadable(isoString) {
    // Parse the ISO string
    const date = new Date(isoString);
  
    // Options for formatting date and time
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
  
    // Format the date and time
    const formattedDateTime = date.toLocaleString('en-GB', options);
  
    return formattedDateTime;
  }


  function getYearFromDate(inputDate) {
    // Create a new Date object from the input
    const date = new Date(inputDate);
  
    // Check if the date is valid
    if (isNaN(date)) {
      throw new Error('Invalid date');
    }
  
    // Extract and return the year
    return date.getFullYear();
  }


  function createMonthlyArray(year, data) {
    const result = [];
  

    for (let month = 0; month < 12; month++) {

      const date = new Date(year, month, 1);
      
      const monthString = String(month + 1).padStart(2, '0');
      // Create date string in YYYY-MM-DD format
      const dateString = `${year}-${monthString}-01`;

      console.log(date, monthString, dateString)

      result.push({ month: dateString, tray_count: 0 });
    }
  
    return result;
  }

  function difference(x, y) {
    const tenPercentOfY = 0.1 * y;
    const difference = Math.abs(x - y);
    
    if(difference > tenPercentOfY) {
        return true
    }

    return false
  }


/**
 * @brief Formats a data field
 * @param {number|string|undefined|null} value 
 * @param {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}} field 
 */
const FormatDataField = (value, field) => {
    if(value === undefined) {
        return "";
    }
    if(value === null) {
        return "-" + field.unit;
    }

    if(field.vName === "Uptime") {
        return formatTime(value);
    }
    if(field.vName === "UVCTime") {
        return formatTime(value);
    }   
        
    if(field.vName === "ExtVolt") {
        return <span style={{color: value > 10 ? 'green' : 'red'}}>{value.toFixed(field.decimals) + field.unit}</span> 
    }   
    
    if(field.vName === "Boxcount") {
 
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0")
    }    
    
    if(field.vName === "chCutoff") {
    
        return value
    }


    if(field.decimals >= 0) {
        return value.toFixed(field.decimals) + field.unit;
    }

    return null;
}


/**
 * @brief Box display element to show device data
 */
export default class DeviceBox extends React.Component {

    /**
     * @type {Device}
     */
    device = null;
    errors = null;
    report = null;
    stats = null;
    definitions = null;

    state = {
        editingName: false,
        tmpName: "",
        headers: dataHeadersAdmin,
        masterHeaders: masterDataHeadersAdmin,
        goToDeviceDetails: false,
        showNote: false,
        noteValue: "",
        editingDeviceName: false,
        note: "",
        saveNote: null
    }

    constructor (props) {
        super(props);
        if(props.device !== null && props.device !== undefined) {
            this.device = props.device;
            this.state.noteValue = this.device.deviceInfo.note;
        }

        if(props.errors !== null && props.errors !== undefined) {
            this.errors = props.errors
        }

        if(props.report !== null && props.report !== undefined) {
            this.report = props.report
        }

        if(props.stats !== null && props.stats !== undefined) {
            this.stats = props.stats
        }

        this.stats = props.stats
        this.definitions = props.definitions

        this.state.note = props.note
        this.state.saveNote = props.saveNote

        this.state.tmpName = this.device.deviceInfo.name;
        this.state.goToDeviceDetails = false;

        this.onNameDoubleClick = this.onNameDoubleClick.bind(this);
        this.onNameValidate = this.onNameValidate.bind(this);
        this.onNameEdit = this.onNameEdit.bind(this);
        this.openDeviceDetails = this.openDeviceDetails.bind(this);
        this.systemsInfoBox = this.systemsInfoBox.bind(this);
        this.systemDataBox = this.systemDataBox.bind(this);
        this.adminView = this.adminView.bind(this);
        this.errorsBox = this.errorsBox.bind(this);
        this.chart = this.chart.bind(this);
        this.noteBox = this.noteBox.bind(this);
        this.notefield = createRef();
    }

    componentDidMount () {

        //    this.setState({note: true});

    }

    /**
     * @brief Callback for when name is double clicked
     * @param {Event} e 
     */
    onNameDoubleClick (e) {

        this.setState({editingName: true});
    }

    /**
     * @brief Callback when exiting name edit mode (blur event)
     */
    onNameValidate () {

        if(this.state.tmpName.length < 3) {
            // FAIL
            this.state.tmpName = this.device.deviceInfo.name;
        }
        else {
            this.device.deviceInfo.name = this.state.tmpName;
            WSController.send(JSON.stringify({
                cmd: ClientWSCommands.SETDEVICENAME,
                device: this.device.deviceInfo.deviceid,
                name: this.device.deviceInfo.name
            }));
        }

        this.setState({editingName: false});
    }

    /**
     * @brief Callback when name is edited
     * @param {Event} e 
     */
    onNameEdit (e) {
        this.setState({tmpName: e.target.value});
    }

    /**
     * @brief Opens device detail page
     * @param {Device} device 
     */
    openDeviceDetails (device) {
        LCSS.selectedDeviceID = device.deviceInfo.deviceid;
        localStorage.setItem("selectedDevice", LCSS.selectedDeviceID);
        this.setState({goToDeviceDetails: true});
    }


    systemDataBox () {
        return <div className="report-dyn-box" style={{boxSizing: 'border-box'}}>
            <table>
                <tbody>
                    {
                        this.device.deviceType === DeviceType.MASTER &&
                        <Fragment>
                        <tr>
                            <td>System uptime</td>
                            <td><b>{formatTime(this.definitions.master.uptime)}</b></td>
                        </tr>
                        <tr>
                            <td>Remote address</td>
                            <td><b>{this.device.deviceInfo.remoteAddress || '-'}</b></td>
                        </tr>
                        <tr>
                            <td>UVC time</td>
                            <td><b>{formatTime(this.definitions.master.uvctime)}</b></td>
                        </tr>
                        <tr>
                            <td>Total trays</td>
                            <td><b>{this.definitions.master.boxcount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0")}</b></td>
                        </tr>
       
                        </Fragment>
                        
                    }
             
       

 
                </tbody>
            </table>
        </div>
    }


    noteBox() {


return <div style={{display: 'flex', flexDirection: 'column', gap: 5, padding: 0}}>

    <b style={{fontSize: 20}}>Notes</b>

<textarea
    onChange={(e) => this.setState({note: e.target.value})}
    onBlur={(e) => this.state.saveNote(e.target.value)}
    value={this.state.note}
    rows={8}
    style={{
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderRadius: 5,
        padding: 10,
        width: "auto",
        fontSize: 16,
    }}
>
</textarea>



</div>



    }


    chart() {

        const formatNumberWithSpaces = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0")
        
        return <div style={{display: 'flex', flexDirection: 'column', gap: "20px", margin: "0px", flex: 1, border: "1px solid #eee", padding: "15px" }}>

        <span style={{fontWeight: "700", fontSize: 20}}>Trays by month</span>
        <div style={{height: "300px", flexShrink: 0, width: "100%", flexGrow: 1}}>
        
        
        <ResponsiveContainer width="100%" height="100%" >          
        
                <BarChart width={150} height={40}  data={createMonthlyArray(getYearFromDate(this.report.month)).map((item, index) => {

                    console.log(this.stats.byMonth, '12345', this.report.month)
const v = this.stats.byMonth.find((i) => i.month === item.month)

            if(v) {
                return {month: index + 1, tray_count: v.tray_count}
            }

            return {month: index + 1, tray_count: 0}
            
        })}>
                <XAxis dataKey="month" />
                <YAxis tickFormatter={formatNumberWithSpaces} />
                <Tooltip formatter={formatNumberWithSpaces} wrapperStyle={{ width: "auto", backgroundColor: '#fff' }} />
                  <Bar dataKey="tray_count" fill="#6257ff" />
                </BarChart>
              </ResponsiveContainer>
        
        </div>
        
        
        </div>

    }

    errorsBox() {

        {console.log(this.errors, 'qwecvx000')}

        return <div style={{display: 'flex', flexDirection: 'column', gap: "5px", margin: "0px"}}>

        <span style={{fontWeight: "700", fontSize: 20}}>Errors</span>



      
              <div style={{width: "100%", display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: "10px"}}>

              <div style={{ display: 'flex', flexDirection: 'column', flex: 1}}>

              <b className="system-tree-field">{"LCDIO-A1"}</b>

              <div role="table" style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
      {/* Header Row */}
      <div role="row" style={{ display: 'flex', width: '100%' }}>
        <div
          role="columnheader"
          style={{
            flex: 1,
            padding: '4px',
            border: '1px solid #ccc',
            boxSizing: 'border-box',
            fontWeight: 'bold',
            backgroundColor: '#f2f2f2',
          }}
        >
          Time
        </div>
        <div
          role="columnheader"
          style={{
            flex: 1,
            padding: '4px',
            border: '1px solid #ccc',
            boxSizing: 'border-box',
            fontWeight: 'bold',
            backgroundColor: '#f2f2f2',
            borderLeft: 0
          }}
        >
          Log
        </div>
      </div>
     

      {this.errors.filter((item) => item.device === this.device.deviceInfo.id).length === 0 ? <span>No errors</span> : this.errors.filter((item) => item.device === this.device.deviceInfo.id).map((item, index) => <div key={"master_errors_" + index} role="row" style={{ display: 'flex', width: '100%' }}>
        <div
          role="cell"
          style={{
            flex: 1,
            padding: '4px',
            border: '1px solid #ccc',
            borderTop: 0,
            boxSizing: 'border-box',
          }}
        >
          {formatISOToReadable(item.time)}
        </div>
        <div
          role="cell"
          style={{
            flex: 1,
            padding: '4px',
            border: '1px solid #ccc',
            borderTop: 0,
            boxSizing: 'border-box',
            borderLeft: 0
          }}
        >
{item.description}
        </div>
      </div>)}

      
    
   
      </div>

      </div>



      {
                                        this.device.slaves.map((v, i) => {
                                            return (

                                                <div key={i} style={{display: 'flex', flexDirection: 'column', flex: 1}}>


<b className="system-tree-field">{"LC-A" + v.deviceInfo.address}</b>

<div role="table" style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
{/* Header Row */}
<div role="row" style={{ display: 'flex', width: '100%' }}>
<div
role="columnheader"
style={{
flex: 1,
padding: '4px',
border: '1px solid #ccc',
boxSizing: 'border-box',
fontWeight: 'bold',
backgroundColor: '#f2f2f2',
}}
>
Time
</div>
<div
role="columnheader"
style={{
flex: 1,
padding: '4px',
border: '1px solid #ccc',
boxSizing: 'border-box',
fontWeight: 'bold',
backgroundColor: '#f2f2f2',
borderLeft: 0
}}
>
Log
</div>
</div>

{this.errors.filter((item) => item.device === v.deviceInfo.id).length === 0 ? <span>No errors</span> : this.errors.filter((item) => item.device === v.deviceInfo.id).map((item, index) => <div key={"slave_errors_" + index}  role="row" style={{ display: 'flex', width: '100%' }}>
<div
role="cell"
style={{
flex: 1,
padding: '4px',
border: '1px solid #ccc',
borderTop: 0,
boxSizing: 'border-box',
}}
>
{formatISOToReadable(item.time)}
</div>
<div
role="cell"
style={{
flex: 1,
padding: '4px',
border: '1px solid #ccc',
borderTop: 0,
boxSizing: 'border-box',
borderLeft: 0
}}
>
{item.description}
</div>
</div>)}




</div>





                                                </div>

                                            )                                        
                                        })
                                    }     

    



 
            
            
     
        
      
   
        
        </div>
        
        
        </div>

    }


    systemsInfoBox () {
        return <div className="report-dyn-box" style={{boxSizing: 'border-box'}}>
            <table>
                <tbody>
                <tr>
                        <td>
                            System
                        </td>
                        <td>
                            {
                                !this.editingDeviceName &&
                                <div>
                                    <b>
                                    {
                                        this.device.deviceInfo.type === DeviceType.MASTER ? 
                                                this.device.deviceInfo.name : 
                                                this.device.master.deviceInfo.name
                                    }
                                    </b>
                                    <img style={{cursor: 'pointer', marginLeft: 8, height: 16, verticalAlign: 'center'}} onClick={this.onEditNameClick} />   
                                </div>
                            }
                            {
                                this.editingDeviceName &&
                                <div>
                                    <input 
                                        type={'text'} 
                                        value={this.deviceTmpName} 
                                        onBlur={this.onNameValidate} 
                                        onSubmit={this.onNameValidate} 
                                        onChange={this.onNameEdit}
                                    />
                                </div>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Device
                        </td>
                        <td>
                            
                            {
                                // Use masters name on a slave
                                this.device.deviceInfo.type === DeviceType.MASTER ? 
                                    "LCDIO-A1" : 
                                    "LC-A" + this.device.deviceInfo.address
                            }
                           
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Device ID
                        </td>
                        <td>
                            {this.device.deviceInfo.deviceid}
                        </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>
                            System devices
                        </td>
                        <td style={{verticalAlign: 'top'}}>
                           
                            {
                                this.device.deviceType === DeviceType.MASTER &&
                                <div>
                                    <b className="system-tree-field">{"LCDIO-A1"}</b>
                                    {
                                        this.device.slaves.map((v, i) => {
                                            return <span className="system-tree-field" key={"slave_" + i}>{"LC-A" + v.deviceInfo.address}</span>;
                                        })
                                    }
                                </div>
                            }
                          
                            {
                                this.device.deviceType === DeviceType.SLAVE &&
                                <div>
                                    <a href={"./device?d=" + this.device.master.deviceInfo.deviceid} className="system-tree-field">{"LCDIO-A1"}</a>
                                    {
                                        this.device.master.slaves.map((v, i) => {
                                            if(v.deviceInfo.deviceid === this.device.deviceInfo.deviceid) {
                                                return <b className="system-tree-field" key={"slave_" + i}>{"LC-A" + v.deviceInfo.address}</b>;
                                            }
                                            else {
                                                return <a href={"./device?d=" + v.deviceInfo.deviceid} className="system-tree-field" key={"slave_" + i}>{"LC-A" + v.deviceInfo.address}</a>;
                                            }
                                        })
                                    }
                                </div>
                            }
                        </td>
                    </tr>
      
                </tbody>
            </table>
        </div>
    }

    
    /**
     * @brief View to display when logged in as admin level user
     */
    adminView () {
        let devstyle = "device-box";
        
        console.log(this.device)
        console.log(this.definitions)

        let firmware = LCSS.firmwares.images.find(e => this.device.firmware === e.id);
        let fwname = firmware ? firmware.name : "-";

        let masterHeaders = masterDataHeadersAdmin;
        let headers = dataHeadersAdmin;


        if(window.innerWidth <= 1024) {
            headers = dataHeadersAdminMobile;
            masterHeaders = masterDataHeadersAdminMobile;

        }

        

        return (
            <div className={devstyle} style={{background: "#fff", boxShadow: 'none', margin: 0, borderRadius: "0px 0px 6px 6px"}}>
                <h1 className={'device-box-title'} style={{textAlign: 'center', margin: 'auto', cursor: 'pointer'}} onClick={() => { this.openDeviceDetails(this.device); }}>
                    {this.device.deviceInfo.name}
                    {
                        this.device.updating &&
                        <span style={{marginLeft: '5px', fontSize: '0.5em'}}>
                            [Updating...]
                        </span>
                    }

                </h1>
                <div style={{padding: "15px", display: 'flex', flexDirection: 'column', gap: 20}}>
                    <div style={{position: 'relative', width: '100%'}}>
                       
<div style={{display: 'flex', alignContent: 'space-between', padding: "0px", gap: 15}} className='device-report'>

<div style={{display: 'flex', flexDirection: 'column', border: "1px solid #eee", padding: "15px", boxSizing: 'border-box' }}>

<span style={{ fontWeight: "700", fontSize: 20 }}>System info</span>

<div style={{display: "flex", flexDirection: 'column', justifyContent: 'space-between'}}>
                
<this.systemsInfoBox />
<this.systemDataBox />

                </div>



</div>
<this.chart />


</div>
                      
              
                    </div>
   <div style={{display: 'flex', flexDirection: 'column', padding: 0}}>
                <span style={{fontWeight: 'bold', paddingLeft: 0, marginBottom: 10}}>LCDIO</span>
                
                <table className="data-table" style={{width: window.innerWidth > 768 ? '100%' : '100%', tableLayout: 'auto', padding: 0}}>
                    <tbody>
                        <tr>
                            {
                                masterHeaders.map((v, i) => {
                                    return (<th key={"MHEADERNAME_" + i}>{v.dName}</th>);
                                })
                            }
                        </tr>
                        <tr onClick={() => {this.openDeviceDetails(this.device)}} style={{cursor: 'pointer'}} className="device-box-select-row">
                            {
                                masterHeaders.map((v, i) => {
                                    let cls = "";
                                    if(this.device.deviceData.Error !== 0) {
                                        cls += "device-error";
                                    }
                
                                    return (
                                        <td key={"MASTERCOL_" + i} className={cls}>{FormatDataField({...this.device.deviceData, ...this.device.masterData, Uptime: this.definitions.master.uptime, UVCTime: this.definitions.master.uvctime, Boxcount: this.definitions.master.boxcount, T1: this.definitions.master.t1, T2: this.definitions.master.t2, RPM : this.definitions.master.rpm, chCutoff: this.device.chCutoff, wifirssi: this.device.deviceType === DeviceType.MASTER && this.device.checkVersion(2, 2) ? this.device.masterData.rssi : 0, wifiretries: this.device.deviceType === DeviceType.MASTER && this.device.checkVersion(2, 2) ? this.device.wificnt : 0}[v.vName], v)}</td>
                                    );
                                })
                            }
                        </tr>
                    </tbody>
                </table>
                <span style={{fontWeight: 'bold', paddingLeft: 0, marginBottom: 0, marginBottom: 10}}>LC</span>
                <table className="data-table" style={{tableLayout: 'auto', padding: 0}}>
                    <tbody>
                        <tr>
                            {
                                headers.map((v, i) => {
                                    return (<th key={"HEADERNAME_" + i}>{v.dName}</th>);
                                })
                            }
                        </tr>
                        {
                            this.device.slaves.map((v, i) => {
                                let cls = "";
                                if(v.deviceData.Error !== 0) {
                                    cls += "device-error";
                                }
                                return (<tr key={"DEVICEROW_" + i} onClick={() => {this.openDeviceDetails(v)}} style={{cursor: 'pointer'}} className="device-box-select-row">
                                    {
                                        headers.map((g, o) => {
                                            if(g.vName === 'Error') {
                                                let err = LCSS.logCodes.find(e => e.code === v.deviceData.Error);
                                                let dtip = "Fetching errors...";
                                                if(err) {
                                                    dtip = err.name + "<br/>" + err.description;
                                                }
                                                return (
                                                    <td key={"DEVICECOL_" + o} data-tip={dtip} className={cls}>
                                                        {FormatDataField({...v.deviceData, ...v.slaveData}[g.vName], g)}
                                                    </td>
                                                );    
                                            }

                                            if(g.vName === 'SETI1') {
                                          
                                                return (
                                                    <td key={"DEVICECOL_" + o} className={cls}>
                                                     {`${this.device.channelDefinitions.max[0]}${g.unit}`}
                                                    </td>
                                                );    
                                            }

                                            if(g.vName === 'I1') {
                                                
                                                return (
                                                    <td key={"DEVICECOL_" + o} className={cls}>
                                                     {difference(v.slaveData.I1, this.device.channelDefinitions.max[0]) ? <span style={{color: 'red'}}>{`${v.slaveData.I1}${g.unit}`}</span> : <span style={{color: 'green'}}>{`${v.slaveData.I1}${g.unit}`}</span>}
                                                    </td> 
                                                );    
                                            }                               
                                            
                                            
                                            if(g.vName === 'SETI2') {

                                                return (
                                                    <td key={"DEVICECOL_" + o} className={cls}>
                                                     {`${this.device.channelDefinitions.max[1]}${g.unit}`}
                                                    </td>
                                                );    
                                            }

                                            if(g.vName === 'I2') {

                                                return (
                                                    <td key={"DEVICECOL_" + o} className={cls}>
                                                     {difference(v.slaveData.I2, this.device.channelDefinitions.max[1]) ? <span style={{color: 'red'}}>{`${v.slaveData.I2}${g.unit}`}</span> : <span style={{color: 'green'}}>{`${v.slaveData.I2}${g.unit}`}</span>}
                                                    </td> 
                                                );    
                                            }   

                                            if(g.vName === 'SETI3') {
                                          
                                                return (
                                                    <td key={"DEVICECOL_" + o} className={cls}>
                                                     {`${this.device.channelDefinitions.max[0]}${g.unit}`}
                                                    </td>
                                                );    
                                            }

                                            if(g.vName === 'I3') {
                                                
                                                return (
                                                    <td key={"DEVICECOL_" + o} className={cls}>
                                                     {difference(v.slaveData.I3, this.device.channelDefinitions.max[2]) ? <span style={{color: 'red'}}>{`${v.slaveData.I3}${g.unit}`}</span> : <span style={{color: 'green'}}>{`${v.slaveData.I3}${g.unit}`}</span>}
                                                    </td> 
                                                );    
                                            }                                          
                                            
                                            
                                            if(g.vName === 'SETI4') {
                                          
                                                return (
                                                    <td key={"DEVICECOL_" + o} className={cls}>
                                                     {`${this.device.channelDefinitions.max[3]}${g.unit}`}
                                                    </td>
                                                );    
                                            }

                                            if(g.vName === 'I4') {
                                                
                                                return (
                                                    <td key={"DEVICECOL_" + o} className={cls}>
                                                     {difference(v.slaveData.I4, this.device.channelDefinitions.max[3]) ? <span style={{color: 'red'}}>{`${v.slaveData.I4}${g.unit}`}</span> : <span style={{color: 'green'}}>{`${v.slaveData.I4}${g.unit}`}</span>}
                                                    </td> 
                                                );    
                                            }

                                            if(g.vName === 'bars') {
                                                
                                                return (
                                                    <td key={"DEVICECOL_" + o} className={cls} style={{display: 'flex', gap: "4px", flexWrap: 'wrap'}}>
                                                   {this.device.channelDefinitions.bars.map((item, index) => <div style={{display: 'flex', gap: "4px"}} key={index}>{index + 1}: <b>{item}</b></div>)}
                                                    </td> 
                                                );    
                                            }

                                            
                                            return <td key={"DEVICECOL_" + o} className={cls}>{FormatDataField({...v.deviceData, U1: this.definitions.slaves[i].u1, U2: this.definitions.slaves[i].u2, U3: this.definitions.slaves[i].u3, U4: this.definitions.slaves[i].u4, I1: this.definitions.slaves[i].i1, I2: this.definitions.slaves[i].i2, I3: this.definitions.slaves[i].i3, I4: this.definitions.slaves[i].i4, chCutoff: v.chCutoff, T1: this.definitions.slaves[i].t1, T2: this.definitions.slaves[i].t2, RPM: this.definitions.slaves[i].rpm
                                             }[g.vName], g)}
                                                </td>;
                                        })
                                    }
                                </tr>);
                            })
                        }
                    </tbody>
                </table>
    </div>
<this.noteBox />


            <this.errorsBox />
            </div>
            </div>
        )
    }

    render () {
        
        if(this.state.goToDeviceDetails)
            return <Redirect push to={"/device?d=" + LCSS.selectedDeviceID} />;

        // Show admin view
        return this.adminView();
    }
}